export const localConfig = {
  REACT_APP_AZURE_CLIENT_ID: '0a9e37bc-3d04-426a-83ee-681bfbfb3075',
  REACT_APP_API_URL: 'https://as-mg-dev1-t1-neu-capitalraisingtool-01.azurewebsites.net',
  REACT_APP_AZURE_REDIRECT_URL: 'http://localhost:3000/home',
  REACT_APP_AZURE_AUTHORITY:
    'https://login.microsoftonline.com/aa42167d-6f8d-45ce-b655-d245ef97da66/oauth2/v2.0/authorize',
  REACT_APP_AZURE_SCOPE: 'api://20e98a24-428d-4799-9912-41be42894ad9/crtuser',
  APP_INSIGHTS_INSTRUMENTATIONKEY: 'fa62aa11-6ac0-456c-b615-9af0d3c312c3',
  REACT_APP_API_ENDPOINT: 'https://as-mg-dev1-t1-weu-capitalraisingtoolapi-01.azurewebsites.net',
  REACT_APP_UI_ENDPOINT: 'https://as-mg-dev1-t1-weu-capitalraisingtool-01.azurewebsites.net'
}

export const getConfig = () => {
  if (process.env.NODE_ENV === 'development') {
    return localConfig
  } else {
    return window.config
  }
}
