import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'
import { getConfig } from '../configs/config'

const browserHistory = createBrowserHistory({ basename: '' })
const userInfoData = localStorage.getItem('userInfoData')
  ? JSON.parse(localStorage.getItem('userInfoData'))
  : null
const reactPlugin = new ReactPlugin()
const config = getConfig()
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: config.APP_INSIGHTS_INSTRUMENTATIONKEY,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
})
ai.loadAppInsights()
ai.trackPageView({
  properties: {
    user: userInfoData?.email || null
  }
})

export default (Component) => withAITracking(reactPlugin, Component)
export const appInsights = ai.appInsights
export { ai, reactPlugin }
