// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Service Worker
import * as serviceWorker from './serviceWorker'

// ** Authentication
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider, AuthenticatedTemplate, useMsal } from '@azure/msal-react'
import { msalConfig, loginRequest } from './authConfig'
import { reactPlugin } from './services/appInsights'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { getConfig } from './configs/config'
// ** Lazy load app

const LazyApp = lazy(() => import('./App'))
//const msalInstance = new PublicClientApplication(msalConfig)
const config = getConfig()
const isDevEnv = process.env.NODE_ENV === 'development'
const msalInstance = new PublicClientApplication({
  ...msalConfig,

  auth: {
    ...msalConfig.auth,

    clientId: isDevEnv ? msalConfig.auth.clientId : config.REACT_APP_AZURE_CLIENT_ID,

    redirectUri: isDevEnv ? msalConfig.auth.redirectUri : config.REACT_APP_AZURE_REDIRECT_URL
  }
})

/* Import DSM css */
import 'dsm-react-lib/dist/index.css'

let accountId = ''

//const [count, setCount] = useEffect(0)
function handleResponse(response) {
  if (response !== null) {
    accountId = response.account.homeAccountId
    // Display signed-in user content, call API, etc.
  } else {
    // In case multiple accounts exist, you can select
    const currentAccounts = msalInstance.getAllAccounts()

    if (currentAccounts.length === 0) {
      // no accounts signed-in, attempt to sign a user in
      msalInstance.loginRedirect(loginRequest)
    } else if (currentAccounts.length > 1) {
      // Add choose account code here
    } else if (currentAccounts.length === 1) {
      accountId = currentAccounts[0].homeAccountId
    }
  }
}

msalInstance.handleRedirectPromise().then(handleResponse)

ReactDOM.render(
  <Provider store={store}>
    <AppInsightsContext.Provider value={reactPlugin}>
      <Suspense fallback={<Spinner />}>
        <ThemeContext>
          <MsalProvider instance={msalInstance}>
            <AuthenticatedTemplate>
              <LazyApp />
            </AuthenticatedTemplate>
          </MsalProvider>
          <ToastContainer newestOnTop />
        </ThemeContext>
      </Suspense>
    </AppInsightsContext.Provider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
