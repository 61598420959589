import { getConfig } from './configs/config'

const config = getConfig()

export const msalConfig = {
  auth: {
    clientId: '0a9e37bc-3d04-426a-83ee-681bfbfb3075',
    authority: 'https://login.microsoftonline.com/aa42167d-6f8d-45ce-b655-d245ef97da66', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: '/',
    postLogoutRedirectUri: '/'
  },
  system: {
    allowRedirectInIframe: true
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [config.REACT_APP_AZURE_SCOPE]
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
}
