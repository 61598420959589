import { combineReducers } from 'redux'
import userContextReducer from './userContextReducer'
import layoutReducer from './layoutReducer'
import navbarReducer from './navbarReducer'

const rootReducer = combineReducers({
  layoutReducer,
  navbarReducer,
  userContextReducer
})

export default rootReducer
